import { Box, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import phone from '../../components/home/phone.svg'
import RegisterModal from '../register/RegisterModal'
import { INFORMATION_TAG_VPBANK } from './Constant'
import './styles.scss'
import img_right from './svg/vpbank_image_right.svg'
import { Partner } from '../../common/Constant'
import { LoanPartner } from './LoanPartner'
import logo_vp from './svg/vpbank.svg'
import logo_kb from './svg/kbank.svg'
import logo_tcb from './svg/tcb.svg'

const SapoFinLandingPage = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [partnerId, setPartnerId] = useState()
  const handleRegister = (id) => {
    setPartnerId(id)
    setShowRegisterModal(true)
  }

  return (
    <>
      <Box className="landingPage">
        <Box className="landingContainer ">
          <Box className="landingOptionsContainer">
            <Box className="text-center" style={{ display: 'flex', justifyContent: 'center' }}>
              <Box className="col-sm-8 col-lg-8 col-xs-12 text-center" style={{ marginTop: 35 }}>
                {/* <Box>
                  <img src={tpBank} alt="Ngân hàng TPBank" />

                  <img src={sapoLogo} className="ml-2" alt="Công ty Sapo" />
                </Box> */}
                <Box className="mt-3">
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      lineHeight: '36px',
                      color: '#182537',
                    }}
                  >
                    Tín chấp tức thì, cấp vốn tiền tỷ
                  </Typography>
                  <Typography className="font-text">
                    Dành riêng cho chủ shop sử dụng dịch vụ Sapo trên 6 tháng
                  </Typography>
                  <Box
                    className="text-left"
                    style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}
                  >
                    <span className="d-block font-text mt-2">
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i> Không tài
                      sản đảm bảo
                    </span>
                    <span className="d-block font-text mt-2">
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i> Đăng ký 
                      vay dễ dàng
                    </span>
                    <span className="d-block font-text mt-2">
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i> Thủ tục 
                      vay đơn giản
                    </span>
                  </Box>
                </Box>
                {/* <Box>
                  <button
                    className="btn btn-primary mt-3 btn-regis"
                    onClick={() => setShowRegisterModal(true)}
                  >
                    Đăng ký vay vốn ngay
                  </button>
                </Box> */}
              </Box>
              <Box className="col-sm-4 col-lg-4 col-xs-12">
                <img
                  src={img_right}
                  style={{ maxWidth: '100%', objectFit: 'cover', height: '200px' }}
                />
              </Box>
            </Box>
          </Box>
          <Box className="landingOptionsContainer" style={{ marginTop: 32 }}>
            <Grid container>
              {INFORMATION_TAG_VPBANK.map((item, index) => (
                <Grid item key={index} xl={3} xs={3} sm={6} md={3} style={{ textAlign: 'center' }}>
                  <Box className={'FinOptionsContainer'} style={{ height: '210px' }}>
                    <Box className="ImgFinOption">
                      <img src={item.icon} />
                    </Box>
                    <Box className="FinOptionTitle">{item.title}</Box>
                    <Box className="FinOptionDescription">{item.content}</Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className="landingOptionsContainer" style={{ marginTop: 32 }}>
            <Grid container spacing={2}>
              <LoanPartner
                icon={logo_vp}
                title={'Sản phẩm chuyên biệt dành cho nhà bán hàng dùng Sapo trên 6 tháng'}
                content={
                  <>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Quy trình thẩm định và phê
                      duyệt nhanh chóng
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Lãi suất cạnh tranh, tính
                      trên dư nợ giảm dần
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Không phát sinh phí ngoài
                      lãi suất
                    </p>
                  </>
                }
                total={'1 tỷ VND'}
                limitTime={'12 - 60 tháng'}
                interestRate={'1.58%/tháng'}
                onAction={() => handleRegister(Partner.VPB)}
                actionContent={'Đăng ký ngay'}
              />
              <LoanPartner
                icon={logo_kb}
                title={'Sản phẩm chuyên biệt dành cho nhà bán hàng dùng Sapo trên 6 tháng'}
                content={
                  <span>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Quy trình thẩm định và phê
                      duyệt nhanh chóng
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Lãi suất cạnh tranh, tính
                      trên dư nợ giảm dần
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Không phát sinh phí ngoài
                      lãi suất
                    </p>
                  </span>
                }
                total={'300 triệu VND'}
                limitTime={'12 - 36 tháng'}
                interestRate={'1.58%/tháng'}
                onAction={() => handleRegister(Partner.KBANK)}
                actionContent={'Đăng ký ngay'}
              />
              <LoanPartner
                icon={logo_tcb}
                title={'Vay thấu chi online - không tài sản đảm bảo'}
                content={
                  <>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Quy trình thẩm định và phê
                      duyệt nhanh chóng
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Lãi suất cạnh tranh, tính
                      trên dư nợ giảm dần
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Không phát sinh phí ngoài
                      lãi suất
                    </p>
                  </>
                }
                total={'500,000,000 VND'}
                limitTime={'12 tháng'}
                interestRate={'0.99%/tháng'}
                onAction={() => handleRegister(Partner.TCB)}
                actionContent={'Đăng ký ngay'}
              />
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box>
        <div className="hotline-phone-ring-wrap">
          <a href="tel:02473086880">
            <img src={phone} alt="Gọi điện thoại" width={50} />
          </a>
        </div>
        {/* <div className="hotline-message-ring-wrap">
          <a href="tel:02473086880" >
            <img src={mess} alt="Gọi điện thoại" width={50} />
          </a>
        </div> */}
      </Box>
      <RegisterModal
        isLanding
        partnerId={partnerId}
        loanAmount={100000000}
        visible={showRegisterModal}
        open={() => setShowRegisterModal(true)}
        close={() => setShowRegisterModal(false)}
      />
    </>
  )
}

export default SapoFinLandingPage
