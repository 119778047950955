import { Modal } from 'antd'
import React, { useContext, useEffect } from 'react'
import RegisterDefault from './RegisterDefault'
import RegisterModalHeader from './RegisterModalHeader'
import { useState } from 'react'
import { Fragment } from 'react'
import NotificationModal from './NotificationModal'
import tenantService from '../../services/tenant/TenantService'
import { decrypt, getKey } from '../../utils/encryptUtil'
import authenticationActionType from '../../contexts/AuthenticationActionType'
import { AuthenticationContext } from '../../contexts/AuthenticationContext'

export default function RegisterModal(props) {
  const { productId, partnerId, time, isLanding, visible, open, close, leadSource } = props
  const [leadId, setLeadId] = useState()
  const [tenant, setTenant] = useState({})
  const { dispatch } = useContext(AuthenticationContext)

  const initialValue = {
    partnerId: partnerId,
    productId: productId,
    time: time,
  }

  useEffect(() => {
    if (visible) {
      setLeadId(null)
    }
  }, [visible])

  useEffect(() => {
    const key = getKey()
    if (key) {
      const keyDecrypt = decrypt(key)
      if (keyDecrypt && keyDecrypt.token) {
        dispatch({
          type: authenticationActionType.signIn,
          payload: keyDecrypt,
        })
      }
    }
    tenantService.getTenantInfo().then((result) => {
      if (result) setTenant(result.tenant)
    })
    return () => {
      console.log('end')
    }
  }, [])

  return (
    <Modal
      visible={visible}
      title={
        leadId ? <Fragment /> : <RegisterModalHeader partnerId={partnerId} isLanding={isLanding} />
      }
      onOk={() => open()}
      onCancel={() => {
        close()
      }}
      width={leadId ? 700 : 800}
      footer={null}
      keyboard={false}
      maskClosable={true}
      closable={true}
      destroyOnClose={true}
      className={'registerLeadModal'}
    >
      {leadId ? (
        <NotificationModal leadId={leadId} />
      ) : (
        <RegisterDefault
          setLeadId={setLeadId}
          initialValue={initialValue}
          tenant={tenant}
          isLanding={isLanding}
          partnerId={partnerId}
          leadSource={leadSource}
        />
      )}
    </Modal>
  )
}
